.portal-name {
	font-size: 14px;
	line-height: 19px;
	color: $white;
	width: 60px;
	text-transform: uppercase;
}

.main-nav {
  justify-content: flex-start;
  padding: 0.5rem 0;

  .navbar-toggler {
	margin-left: auto;
	border: none;
	padding: 0;
	font-size: 24px;
	margin-right: 1rem;

	&:focus {
	  outline: none;
	}
  }

  .navbar-brand {
	margin-left: 1rem;
  }

  .navbar-nav {
		margin-top: 0.5rem;
		.nav-link {
	  	border-radius: unset;
	  	color: $white;
	  	font-weight: $font-weight-bold;
	  	padding: 0.5rem 1rem;

	  	&.active {
				background-color: theme-color('secondary');
	  	}
		}

		.show > .nav-link {
	  	background: none;
	  	outline: none;
		}
  }

  .btn-link {
	color: $white;
	border: none;
	font-size: 14px;
	line-height: 19px;
	padding: 1rem;

	&:hover {
	  text-decoration: none;
	}

	&:focus {
	  outline: none;
	  border: none;
	  box-shadow: none;
	  text-decoration: none;
	}
  }

	.dropdown {
		.dropdown-toggle {
			&.focus-visible,
			&:focus-visible {
				outline: 2px solid $black !important;
				padding-bottom: 15px;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
  .main-nav {
	padding: 0;
	height: 70px;

	.navbar-collapse {
	  height: 100%;
	}

	.navbar-nav {
	  margin-top: 0;
	  height: 100%;

	  .nav-link {
		display: -webkit-box;
		font-size: 17px;
		line-height: 25px;
		text-align: center;
		padding: 0 1.25rem;
		height: 100%;
		overflow: hidden;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	  }
	}
  }
}

@include media-breakpoint-up(md) {
	.main-nav {
		.navbar-nav {
			.nav-link {
				&.has-badge {
					position: relative;
					padding-right: 3rem;
					.badge {
						right: 1.5rem;
						top: 1rem;
					}
					&.md {
						.badge {
							right: 1.25rem;
							top: 0.75rem;
						}
					}
					&.lg {
						padding-right: 3.5rem;
						.badge {
							right: 1.25rem;
							top: 0.5rem;
						}
					}
				}
				.badge {
					position: absolute;
				}
			}
		}
	}
}
