body {
	overflow-y: scroll;
}

// Search box
.search-box-with-icon {
	position: relative;

	.search-icon {
		position: absolute;
		top: 10px;
		left: 10px;
	}

	.clear-btn {
		position: absolute;
		right: 0;
	}

	.form-control {
		padding-left: 35px;
		padding-right: 50px;
	}
}

.z-index-1 {
	z-index: 1;
}

.row-bg {
	margin-top: -1rem;
	background-color: $blue-light-2;
}

.badge {
	&.circle {
		border-radius: 50%;
		padding: 0.25rem 0.4rem;
		&.md {
			padding: 0.35rem 0.25rem;
		}
		&.lg {
			padding: 0.6rem 0.25rem;
		}
	}
}

.chip {
	align-items: center;
	display: inline-flex;
	margin-right: 0.25rem;
	margin-bottom: 0.25rem;
	gap: 0.5rem;

	&-clear {
		background-color: $light !important;
		border-radius: 50%;
		display: grid;
		place-items: center;
		width: 1.5rem;
		height: 1.5rem;
	}
}

// additional-field
.additional-field {
  .remove-btn-container {
	position: absolute;
	top: -12px;
	right: -12px;

	.btn-remove {
	  background-color: $white;
	  width: 28px;
	  height: 28px;
	  padding: 0;
	  border-radius: 15px;

	  &:hover {
		background-color: $danger;
	  }
	}
  }
}

video {
	&.hide-full-screen::-webkit-media-controls-fullscreen-button {
		display: none;
	}
}

//show and hide password
.show-hide-password-btn {
	button {
		border-color: $gray-400;
	}
}

//appointment calendar
.fc-day {
	cursor: pointer;
}
//appointment status
.status-text {
	font-weight: bold;
}
.appointment-own-icon {
	margin-top: 10px;
	text-align: right;
}

.min {
	&-w {
		&-92 {
			min-width: 92px;
		}
	}
}
