.btn {
	&-circle {
		background-color: $white;
		width: 30px;
		height: 30px;
		padding: 0;
		line-height: 0;
		border-radius: 15px;
		border: 1px solid $gray-500;

		&-sm {
			@extend .btn-circle;
			width: 20px;
			height: 20px;
		}

		&-lg {
			width: 40px;
			height: 40px;
		}

		&-sm,
		&-lg {
			@include border-radius(100%);
		}

		&-primary {
			background-color: $white;
			border-color: $primary;

			&:hover {
				border-color: $secondary;
				background-color: $secondary;
			}
		}
	}

	&-light-blue {
		background-color: $blue-light;
		padding: 0;
	}

	&-in-btn {
		position: absolute;
		top: -10px;
		right: -10px;
	}

	&-with {
		&-warning {
			position: relative;

			.badge {
				position: absolute;
				right: -2px;
				bottom: -2px;
				height: 18px;
				width: 18px;
			}
		}
	}

	&:hover,
	&:focus,
	&:active {
		.center-icon {
			border-color: $secondary;
			background-color: $secondary;
			color: $white;
		}
	}
}

.react-checkbox-tree {
	button {
		&.focus-visible,
		&:focus-visible {
			outline: 1px solid $dark !important;
		}
	}
}

.chat-message {
	.btn {
		&-with {
			&-warning {
				position: initial;
				.badge {
					top: 20px;
					right: -10px;
				}
				.chat-send-btn {
					cursor: pointer;
				}
			}
		}
	}
}
