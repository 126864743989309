/* make the customizations */

// Font
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
$font-family-base: Nunito, Arial, sans-serif;

// Color
$red: #FB4E4E;
$orange: #E35205;
$orange-light: #EDC8A3;
$yellow: #FFD100;
$green: #1AB759;
$blue: #0077C8;
$teal: #EAF9F0;
$gray-500: #ADADAD;
$gray-600: #858585;
$gray-700: #777777;
$gray-900: #333333;

$primary: $blue;
$secondary: #06038D;
$info: #99C9E9;
$info-light: #DEE7FF;
$danger: $red;
$light: #EBEBEB;
$dark: #5C5C5C;
$blue-light: #B3D6EF;
$blue-light-2: #E6F1FA;
$light-grey: #cacfcc;
$light-grey1: #ced4da;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $green,
	"info": $info,
	"info-light": $info-light,
	"danger": $danger,
	"light": $light,
	"dark": $dark,
	"blue-light-2": $blue-light-2,
	"orange": $orange,
);

// Share SCSS Variables to Javascript
:export {
	primary: $primary;
	secondary: $secondary;
	success: $green;
	info: $info;
	danger: $danger;
	orange: $orange;
	infoLight: $info-light;
	orangeLight: $orange-light;
	dark: $dark;
};

// Toasts
$toast-background-color: $teal;
$toast-header-background-color: $teal;
$toast-border-color: $green;
$toast-header-color: $green;
$toast-color: $green;

$toast-border-radius: .5rem;

$toast-min-width: 200px;

// Z-index
$zindex-toast: 1080;

// Font size
$h1-font-size: 1.5rem;
$headings-line-height: 1.5rem;
$small-font-size: 14px;

$text-muted: $gray-600;
$body-color: $gray-900;
$input-color: $gray-900;
$input-focus-color: $gray-900;

$placeholder-opacity-max: .5;
$placeholder-opacity-min: .2;
