@each $color, $value in $theme-colors {
	.fc .fc-button-#{$color} {
		@include button-variant($value, $value);
	}
}

.fc {
	&-daygrid {
		&-day {
			&.fc {
				&-day {
					&:hover {
						background-color: lighten($primary, 55%);
					}
				}
			}
		}
	}
}