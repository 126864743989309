.loading-shading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, .5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10000;
}


.loading-spinner {
	position: absolute;
	top: calc(50%);
	left: calc(50% - 20px);
}
