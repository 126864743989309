.dx-g-bs4-table-cell.position-sticky {
	overflow: visible;
}

.table thead {
	th {
		background-color: theme-color('light') !important;
	}
	tr.filter th {
		background-color: theme-color('dark') !important;
	}

	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker th.rdtSwitch,
	.rdtPicker .rdtDays th {
	  background-color: theme-color('light') !important;
	}
}

.questionnaire-title {
	white-space: pre-wrap;

	.description {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}
	.title {
		color: $primary;
	}
}

.indent-first-heading {
	thead {
		th:first-child {
			.dx-rg-bs4-table-header-title {
				text-indent: -9999px;
			}
		}
	}
}
