.exercise-card {
	overflow: hidden;
	height: 340px;
	&:hover {
		cursor: pointer;
	}

	&:focus {
		outline: 0 none;
	}

	&.focus-visible,
	&:focus-visible {
		outline: 5px auto $dark !important;
	}

	.img-fluid {
		width: 100%;
		height: 157px;
		object-fit: cover;
	}

	.card-img {
		height: 157px;
	}

  	.card-body {
	  height: 147px;
	}

	.card-title {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		&:hover {
			cursor: pointer;
		}
	}

	.action {
		z-index: 1;
	}
}

.edit-btn,
.delete-btn {
	display: flex;
	justify-content: flex-end;
  	z-index: 1;
	margin-top: -20px;
	padding-right: 5px;
}

.delete-btn {
  margin-top: -25px;
}

.owner-btn {
  top: 0;
  z-index: 1;
  color: $primary;
}

.favorite-btn {
  top: 0;
  z-index: 1;

  &.btn-link:hover {
	color: $secondary;
  }
}

.btn-link {
  color: $primary;
}

a {
  color: $primary;
}

.top-bar {
	background-color: rgba(255, 255, 255, 0.5);
	cursor: auto;
	display: flex;
	justify-content: space-between;
	padding: 3px 10px;
	width: 100%;

	.custom-control-label {
		text-indent: -9999px;

		&:before,
		&:after {
			cursor: pointer;
			left: -20px;
			height: 20px;
			width: 20px;
		}

		&:before {
			border-color: $primary;
		}
	}

	.custom-checkbox {
		.custom-control-label {
			&:before {
				border-radius: 50%;
			}
		}
	}
}

.exercise-media {
	.upload-btn {
		opacity: 0;
		right: 0;
		top: 0;
	}

	.remove-btn-wrapper {
		right: -10px;
		top: -10px;
		bottom: 0;
	}
}

.questionnaire-header {
	background-color: $secondary;
	color: $white;
}

.question-card {
	.card-header {
		background-color: $blue-light-2;
	}
	.remove-btn {
		background-color: $white;
		width: 25px;
		height: 25px;
		padding: 0;
		border-radius: 15px;

		&:hover {
			background-color: $danger;
		}
	}
}

.view-question-card-header {
	background-color: $blue-light-2;
	height: 45px;
	&:hover {
		cursor: pointer;
	}
}

.remove-btn[disabled] {
	background: none;

	&:hover {
		background: none;
	}
}

.drag-button:hover {
	cursor:grabbing;
}

.upload-btn {
	opacity: 0;
	right: 0;
	top: 0;
}

.card-remove-btn-wrapper {
	z-index: 1;
	right: 0;
}

.modal-backdrop.show, .modal.show {
	z-index: 1051 !important;
}

.view-exercise-carousel {
	.carousel-control-next, .carousel-control-prev {
		height: 30px;
		top: 40%;
	}
	.carousel-indicators {
		height: 15px;
	}
	.audio-wrapper {
		height: 120px;
	}
}

.questionnaire-edit-btn {
	color: $white;
	right: 20px;
	z-index: 1;
	top: 0;
}

.questionnaire-own-icon {
	top: 0;
	left: 50%;
	z-index: 1;
	color: $white;
}
.edit-icon-color {
	color: $white;
}

.question-wrapper:last-child {
	margin-bottom: 120px;
}

.sticky-btn {
	position: fixed;
	bottom: 0;
	background-color: $white;
	width: 100%;
	border-top: 1px solid $light-grey;
	margin-left: -13px;
}
.questionnaire-save-cancel-wrapper {
	margin-right: 8%;
	display: flex;
}
