.chat {
  &-sidebar-panel {
    height: calc(100vh - 4.5rem);
    background-color: $white;
    padding-right: 0;
    padding-left: 0;
    .badge {
      font-size: $font-size-sm;
      min-height: 1.5rem;
      min-width: 1.5rem;
      border-radius: 50%;
      padding: 0;
    }
    @include media-breakpoint-up(md) {
      position: sticky;
      top: 4rem;
      z-index: 1000;
      height: calc(100vh - 4.5rem);
      border-right: 1px solid $gray-500;
    }
  }
  &-sidebar-header {
    padding-right: 15px;
    padding-left: 15px;
    background-color: $blue-light-2;
  }
  &-room-list {
    background-color: $white;
    overflow-y: auto;
    .list-group {
      border-radius: 0;
      &-item {
        border-width: 0;
        border-bottom: 1px solid $border-color;
        border-left: 5px solid $white;
        font-weight: $font-weight-bold;
        padding: 8px 15px 8px 10px;
        cursor: pointer;
        min-height: 61px;
        line-height: 1.25rem;
        &:hover,
        &.active {
          border-left-color: $primary;
          background-color: $blue-light-2;
        }
        &.active {
          color: $body-color;
        }
      }
    }
  }
  &-room-item {
    width: 65%;
    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
  &-user-status {
    margin-left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.md {
      margin-left: 5px;
      width: 14px;
      height: 14px;
    }
    &.online {
      background-color: $green;
    }
    &.offline {
      background-color: $gray-500;
    }
    &.busy {
      background-color: $red;
    }
    &.away {
      background-color: $yellow;
    }
  }
  &-message {
    &-panel {
      background-color: theme-color('light');
      overflow: hidden;
      @include media-breakpoint-up(md) {
        height: calc(100vh - 4.40rem);
      }
    }
    &-header {
      border-bottom: 1px solid $gray-500;
      padding: 15px 0;
      .btn-audio-call,
      .btn-video-call {
        height: 40px;
        width: 40px;
      }
      .btn-back {
        padding-left: 0;
        &:focus {
          box-shadow: none;
        }
      }
    }
    &-list {
      overflow-y: auto;
    }
    &-day {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      color: $gray-700;
    }
    &-bubble {
      padding: 10px;
      border-radius: 0.9375rem;
      min-height: 20px;
      &.left {
        background-color: $white;
        margin-right: 3.75rem;
      }
      &.right {
        background-color: $blue;
        margin-left: 3.75rem;
        justify-content: flex-end;
        color: $white;
      }
    }
    &-attachment {
      width: 320px;
      max-width: 320px;
      img,
      video {
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
    &-info {
      line-height: 1;
    }
    &-time {
      font-size: 12px;
    }
    &-pending {
      top: 0;
      left: 0;
      background: rgba($white, .5);
    }
  }
  &-input-toolbar {
    margin: 15px 0;
    .form-control {
      min-height: 50px;
      padding: 13px 48px 13px 15px;
      line-height: 22px;
      overflow-y: hidden;
      resize: none;
      border-color: $light;
      border-radius: $border-radius-lg;
      &:focus {
        box-shadow: none;
        border-color: $light;
      }
    }
  }
  &-composer {
    width: 92%;
  }
  &-send-btn {
    position: absolute;
    right: 13px;
    bottom: 12px;
    &:focus {
      box-shadow: none;
    }
  }
  &-add-attachment-btn {
    width: 50px;
    height: 50px;
    font-size: $font-size-sm;
    background-color: $white;
    border-radius: $border-radius-lg;
    margin-left: 15px;
    color: $gray-600;
    border-color: $light;
    &:hover,
    &:focus {
      color: $gray-600;
      box-shadow: none;
    }
    .upload-btn {
      width: 50px;
      height: 50px;
      @include media-breakpoint-up(md) {
        width: 100px;
      }
    }
    @include media-breakpoint-up(md) {
      width: 100px;
    }
  }
  &-attachment-overview {
    video {
      &:focus {
        outline: none;
      }
    }
  }
}

.panel-no-sidebar {
  .chat-sidebar-panel {
    max-width: 0;
    display: none !important;
  }
  .chat-message-panel {
    padding: 0;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.calling {
  background-color: $dark;
  position: fixed;
  top: 59px;
  height: calc(100vh - 59px);
  width: 100%;
  z-index: 1000;
  @include media-breakpoint-up(xl) {
    top: 70px;
    height: calc(100vh - 70px);
  }
  .sidebar-toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    &:focus {
      box-shadow: none;
    }
  }
  .incoming {
    background-color: $black;
    height: 100%;
    width: 100%;
    &-participant {
      h2 {
        color: $white;
        font-weight: 700;
        font-size: 38px;
        margin: 0 0 15px 0;
      }
      p {
        color: $primary;
        font-style: italic;
        font-size: 18px;
      }
    }
    &-options {
      margin: 0 0 44px 0;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          color: $white;
          padding: 0 5px;
          .btn {
            &-video-call,
            &-muted-call,
            &-accept-call,
            &-end-call {
              border-radius: 50%;
              height: 46px;
              width: 46px;
            }
          }
        }
      }
    }
  }
  .room {
    height: 100%;
    width: 100%;
    .participant {
      &-name {
        font-size: 18px;
        font-weight: 600;
        position: absolute;
        left: 16px;
        top: 7px;
        margin: 0;
        z-index: 99;
      }
    }
    .transcript-language {
      position: absolute;
      left: 16px;
      top: 38px;
      margin: 0;
      z-index: 99;
      width: 200px;
    }
    .local,
    .remote {
      .participant,
      video {
        background-color: $black;
        height: 100%;
        width: 100%;
      }
    }
    .local {
      height: 100%;
      width: 100%;
      .participant {
        &-avatar {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .remote {
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: absolute;
      top: 60px;
      right: 15px;
      &-participant {
        border: 2px solid $primary;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        height: 160px;
        width: 210px;
        .participant {
          &-avatar {
            background-color: $dark;
            display: grid;
            place-items: center;
            height: 100%;
            width: 100%;
          }
          &-identity {
            color: #000000;
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            margin: 0 0 6px 0;
            width: 100%;
          }
        }
      }
    }
  }
  .btn {
    &-add {
      &-participant {
        min-width: 210px;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 999;
      }
    }
  }
}
