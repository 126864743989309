.table {
	th,
	td {
		vertical-align: middle;
	}

	.hover-primary {
		&:hover {
			background-color: $secondary;
			color: $white;

			.notify-list-item {
				.btn {
					&-link {
						color: $white !important;

						svg {
							color: $white !important;
						}
					}
				}
			}
		}
	}
}
