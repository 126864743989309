.activity-card-wrapper {
	width: 100%;
	min-height: 250px;
}

.show-select-exercise-button {
	right: 0;
	top: 7px;
}

.selected-exercise-wrapper {
	right: 0;
	top: 58px;
	height: 93%;
	overflow-y:scroll;
	background-color: $light;
	z-index: 99;
	padding: $spacer;
}

.activity-weekend-header-column-background {
	background-color: $orange;
}

.material-category-wrapper {
	margin-bottom: 6%;
}

.custom-checkbox {
  svg {
	stroke-width: 4px;
  }
}

.card-remove-btn-wrapper {
  .btn-circle-sm {
	border-color: $primary;
	border-width: 1.5px;
	color: $primary;

	&:hover {
	  border-color: $secondary;
	  background-color: $secondary;
	  color: $white;
	}

	svg {
	  stroke-width: 2px;
	}
  }
}

.custom-checkbox {
  .custom-checkbox-item {
	display: flex;
	flex: 1 1 0%;
	background-color: $primary;
	align-self: stretch;
  }

  &.disabled {
	opacity: 0.65;
  }
}
